export default defineNuxtPlugin((nuxtApp) => {
  onNuxtReady(async () => {
    const gtagId = useRuntimeConfig().public.googleAnalytics.accountId;

    function gtag(...args) {
      window['dataLayer'].push(arguments);
    }

    window['dataLayer'] = window['dataLayer'] || [];

    gtag('js', new Date());
    gtag('config', gtagId);

    useHead({
      script: [
        {
          src: `https://www.googletagmanager.com/gtag/js?id=${gtagId}`,
          async: true
        }
      ]
    });
  });
});
