import { default as _91id_93rZRxTCltEUMeta } from "/app/apps/onboarding/pages/[orgId]/[context]/[classId]/invitation/[id].vue?macro=true";
import { default as _91type_93_45passwordeApc6ZGLtGMeta } from "/app/apps/onboarding/pages/[type]-password.vue?macro=true";
import { default as accessibilityjZLATnH1UiMeta } from "/app/apps/onboarding/pages/accessibility.vue?macro=true";
import { default as authenticateA5HXlWZUzFMeta } from "/app/apps/onboarding/pages/authenticate.vue?macro=true";
import { default as child_45registrationSDK5Y1naHyMeta } from "/app/apps/onboarding/pages/child-registration.vue?macro=true";
import { default as edulogTXKuw3ysjJMeta } from "/app/apps/onboarding/pages/edulog.vue?macro=true";
import { default as error_45card6j6KtmzetRMeta } from "/app/apps/onboarding/pages/error-card.vue?macro=true";
import { default as generic_45lti_45errortkxnL5U5GPMeta } from "/app/apps/onboarding/pages/generic-lti-error.vue?macro=true";
import { default as homeFDS30BusBuMeta } from "/app/apps/onboarding/pages/home.vue?macro=true";
import { default as institution_45requestDFeFXi0c4mMeta } from "/app/apps/onboarding/pages/institution-request.vue?macro=true";
import { default as learner_45age_45_91type_93GqBVIYt5vzMeta } from "/app/apps/onboarding/pages/learner-age-[type].vue?macro=true";
import { default as login_91_91segment_93_93hgTtOFMUBbMeta } from "/app/apps/onboarding/pages/login[[segment]].vue?macro=true";
import { default as _91ltik_93Acr4DMxPqEMeta } from "/app/apps/onboarding/pages/lti-activate-course-material/[spaceId]/class/[classId]/product/[productCode]/resource/[resource]/ltik/[ltik].vue?macro=true";
import { default as _91ltik_93bmB3OZ9hFKMeta } from "/app/apps/onboarding/pages/lti-component-selection/[spaceId]/class/[classId]/bundle/[bundleCode]/resource/[resource]/ltik/[ltik].vue?macro=true";
import { default as lti_45errorSUSaVXO2HbMeta } from "/app/apps/onboarding/pages/lti-error.vue?macro=true";
import { default as _91errorType_930JfnEjLW5VMeta } from "/app/apps/onboarding/pages/lti-onboarding-error/[errorType].vue?macro=true";
import { default as lti_45onboardingUyXkOAOAaoMeta } from "/app/apps/onboarding/pages/lti-onboarding.vue?macro=true";
import { default as _91ltik_93Y3DPYiUg3zMeta } from "/app/apps/onboarding/pages/lti-product-selection/[spaceId]/class/[classId]/resource/[resource]/ltik/[ltik].vue?macro=true";
import { default as _91ltik_93kDfkhSYzQ1Meta } from "/app/apps/onboarding/pages/lti-progress-selection/[spaceId]/class/[classId]/resource/[resource]/ltik/[ltik].vue?macro=true";
import { default as _91ltik_938C6FNZZzkkMeta } from "/app/apps/onboarding/pages/lti-teacher-dashboard/[spaceId]/class/[classId]/ltik/[ltik].vue?macro=true";
import { default as oktaWyeQaTzaaZMeta } from "/app/apps/onboarding/pages/okta.vue?macro=true";
import { default as parental_45consentO3wTq1mVVfMeta } from "/app/apps/onboarding/pages/parental-consent.vue?macro=true";
import { default as privacyFEA7SJJYubMeta } from "/app/apps/onboarding/pages/privacy.vue?macro=true";
import { default as register_45_91user_93ZbxKT0U3tAMeta } from "/app/apps/onboarding/pages/register-[user].vue?macro=true";
import { default as regoptions8USvTGKJj9Meta } from "/app/apps/onboarding/pages/regoptions.vue?macro=true";
import { default as social_45loginrzjZZFAV66Meta } from "/app/apps/onboarding/pages/social-login.vue?macro=true";
import { default as terms56rJL2k8eSMeta } from "/app/apps/onboarding/pages/terms.vue?macro=true";
import { default as verification_45link_45expiredZuz0h011W4Meta } from "/app/apps/onboarding/pages/verification-link-expired.vue?macro=true";
import { default as homeRnoOz6vqi0Meta } from "~/pages/home.vue?macro=true";
export default [
  {
    name: _91id_93rZRxTCltEUMeta?.name ?? "orgId-context-classId-invitation-id",
    path: _91id_93rZRxTCltEUMeta?.path ?? "/:orgId()/:context()/:classId()/invitation/:id()",
    meta: _91id_93rZRxTCltEUMeta || {},
    alias: _91id_93rZRxTCltEUMeta?.alias || [],
    redirect: _91id_93rZRxTCltEUMeta?.redirect,
    component: () => import("/app/apps/onboarding/pages/[orgId]/[context]/[classId]/invitation/[id].vue").then(m => m.default || m)
  },
  {
    name: _91type_93_45passwordeApc6ZGLtGMeta?.name ?? "type-password",
    path: _91type_93_45passwordeApc6ZGLtGMeta?.path ?? "/:type()-password",
    meta: _91type_93_45passwordeApc6ZGLtGMeta || {},
    alias: _91type_93_45passwordeApc6ZGLtGMeta?.alias || [],
    redirect: _91type_93_45passwordeApc6ZGLtGMeta?.redirect,
    component: () => import("/app/apps/onboarding/pages/[type]-password.vue").then(m => m.default || m)
  },
  {
    name: accessibilityjZLATnH1UiMeta?.name ?? "accessibility",
    path: accessibilityjZLATnH1UiMeta?.path ?? "/accessibility",
    meta: accessibilityjZLATnH1UiMeta || {},
    alias: accessibilityjZLATnH1UiMeta?.alias || [],
    redirect: accessibilityjZLATnH1UiMeta?.redirect,
    component: () => import("/app/apps/onboarding/pages/accessibility.vue").then(m => m.default || m)
  },
  {
    name: authenticateA5HXlWZUzFMeta?.name ?? "authenticate",
    path: authenticateA5HXlWZUzFMeta?.path ?? "/authenticate",
    meta: authenticateA5HXlWZUzFMeta || {},
    alias: authenticateA5HXlWZUzFMeta?.alias || [],
    redirect: authenticateA5HXlWZUzFMeta?.redirect,
    component: () => import("/app/apps/onboarding/pages/authenticate.vue").then(m => m.default || m)
  },
  {
    name: child_45registrationSDK5Y1naHyMeta?.name ?? "child-registration",
    path: child_45registrationSDK5Y1naHyMeta?.path ?? "/child-registration",
    meta: child_45registrationSDK5Y1naHyMeta || {},
    alias: child_45registrationSDK5Y1naHyMeta?.alias || [],
    redirect: child_45registrationSDK5Y1naHyMeta?.redirect,
    component: () => import("/app/apps/onboarding/pages/child-registration.vue").then(m => m.default || m)
  },
  {
    name: edulogTXKuw3ysjJMeta?.name ?? "edulog",
    path: edulogTXKuw3ysjJMeta?.path ?? "/edulog",
    meta: edulogTXKuw3ysjJMeta || {},
    alias: edulogTXKuw3ysjJMeta?.alias || [],
    redirect: edulogTXKuw3ysjJMeta?.redirect,
    component: () => import("/app/apps/onboarding/pages/edulog.vue").then(m => m.default || m)
  },
  {
    name: error_45card6j6KtmzetRMeta?.name ?? "error-card",
    path: error_45card6j6KtmzetRMeta?.path ?? "/error-card",
    meta: error_45card6j6KtmzetRMeta || {},
    alias: error_45card6j6KtmzetRMeta?.alias || [],
    redirect: error_45card6j6KtmzetRMeta?.redirect,
    component: () => import("/app/apps/onboarding/pages/error-card.vue").then(m => m.default || m)
  },
  {
    name: generic_45lti_45errortkxnL5U5GPMeta?.name ?? "generic-lti-error",
    path: generic_45lti_45errortkxnL5U5GPMeta?.path ?? "/generic-lti-error",
    meta: generic_45lti_45errortkxnL5U5GPMeta || {},
    alias: generic_45lti_45errortkxnL5U5GPMeta?.alias || [],
    redirect: generic_45lti_45errortkxnL5U5GPMeta?.redirect,
    component: () => import("/app/apps/onboarding/pages/generic-lti-error.vue").then(m => m.default || m)
  },
  {
    name: homeFDS30BusBuMeta?.name ?? "home",
    path: homeFDS30BusBuMeta?.path ?? "/home",
    meta: homeFDS30BusBuMeta || {},
    alias: homeFDS30BusBuMeta?.alias || [],
    redirect: homeFDS30BusBuMeta?.redirect,
    component: () => import("/app/apps/onboarding/pages/home.vue").then(m => m.default || m)
  },
  {
    name: institution_45requestDFeFXi0c4mMeta?.name ?? "institution-request",
    path: institution_45requestDFeFXi0c4mMeta?.path ?? "/institution-request",
    meta: institution_45requestDFeFXi0c4mMeta || {},
    alias: institution_45requestDFeFXi0c4mMeta?.alias || [],
    redirect: institution_45requestDFeFXi0c4mMeta?.redirect,
    component: () => import("/app/apps/onboarding/pages/institution-request.vue").then(m => m.default || m)
  },
  {
    name: learner_45age_45_91type_93GqBVIYt5vzMeta?.name ?? "learner-age-type",
    path: learner_45age_45_91type_93GqBVIYt5vzMeta?.path ?? "/learner-age-:type()",
    meta: learner_45age_45_91type_93GqBVIYt5vzMeta || {},
    alias: learner_45age_45_91type_93GqBVIYt5vzMeta?.alias || [],
    redirect: learner_45age_45_91type_93GqBVIYt5vzMeta?.redirect,
    component: () => import("/app/apps/onboarding/pages/learner-age-[type].vue").then(m => m.default || m)
  },
  {
    name: login_91_91segment_93_93hgTtOFMUBbMeta?.name ?? "loginsegment",
    path: login_91_91segment_93_93hgTtOFMUBbMeta?.path ?? "/login:segment?",
    meta: login_91_91segment_93_93hgTtOFMUBbMeta || {},
    alias: login_91_91segment_93_93hgTtOFMUBbMeta?.alias || [],
    redirect: login_91_91segment_93_93hgTtOFMUBbMeta?.redirect,
    component: () => import("/app/apps/onboarding/pages/login[[segment]].vue").then(m => m.default || m)
  },
  {
    name: _91ltik_93Acr4DMxPqEMeta?.name ?? "lti-activate-course-material-spaceId-class-classId-product-productCode-resource-resource-ltik-ltik",
    path: _91ltik_93Acr4DMxPqEMeta?.path ?? "/lti-activate-course-material/:spaceId()/class/:classId()/product/:productCode()/resource/:resource()/ltik/:ltik()",
    meta: _91ltik_93Acr4DMxPqEMeta || {},
    alias: _91ltik_93Acr4DMxPqEMeta?.alias || [],
    redirect: _91ltik_93Acr4DMxPqEMeta?.redirect,
    component: () => import("/app/apps/onboarding/pages/lti-activate-course-material/[spaceId]/class/[classId]/product/[productCode]/resource/[resource]/ltik/[ltik].vue").then(m => m.default || m)
  },
  {
    name: _91ltik_93bmB3OZ9hFKMeta?.name ?? "lti-component-selection-spaceId-class-classId-bundle-bundleCode-resource-resource-ltik-ltik",
    path: _91ltik_93bmB3OZ9hFKMeta?.path ?? "/lti-component-selection/:spaceId()/class/:classId()/bundle/:bundleCode()/resource/:resource()/ltik/:ltik()",
    meta: _91ltik_93bmB3OZ9hFKMeta || {},
    alias: _91ltik_93bmB3OZ9hFKMeta?.alias || [],
    redirect: _91ltik_93bmB3OZ9hFKMeta?.redirect,
    component: () => import("/app/apps/onboarding/pages/lti-component-selection/[spaceId]/class/[classId]/bundle/[bundleCode]/resource/[resource]/ltik/[ltik].vue").then(m => m.default || m)
  },
  {
    name: lti_45errorSUSaVXO2HbMeta?.name ?? "lti-error",
    path: lti_45errorSUSaVXO2HbMeta?.path ?? "/lti-error",
    meta: lti_45errorSUSaVXO2HbMeta || {},
    alias: lti_45errorSUSaVXO2HbMeta?.alias || [],
    redirect: lti_45errorSUSaVXO2HbMeta?.redirect,
    component: () => import("/app/apps/onboarding/pages/lti-error.vue").then(m => m.default || m)
  },
  {
    name: _91errorType_930JfnEjLW5VMeta?.name ?? "lti-onboarding-error-errorType",
    path: _91errorType_930JfnEjLW5VMeta?.path ?? "/lti-onboarding-error/:errorType()",
    meta: _91errorType_930JfnEjLW5VMeta || {},
    alias: _91errorType_930JfnEjLW5VMeta?.alias || [],
    redirect: _91errorType_930JfnEjLW5VMeta?.redirect,
    component: () => import("/app/apps/onboarding/pages/lti-onboarding-error/[errorType].vue").then(m => m.default || m)
  },
  {
    name: lti_45onboardingUyXkOAOAaoMeta?.name ?? "lti-onboarding",
    path: lti_45onboardingUyXkOAOAaoMeta?.path ?? "/lti-onboarding",
    meta: lti_45onboardingUyXkOAOAaoMeta || {},
    alias: lti_45onboardingUyXkOAOAaoMeta?.alias || [],
    redirect: lti_45onboardingUyXkOAOAaoMeta?.redirect,
    component: () => import("/app/apps/onboarding/pages/lti-onboarding.vue").then(m => m.default || m)
  },
  {
    name: _91ltik_93Y3DPYiUg3zMeta?.name ?? "lti-product-selection-spaceId-class-classId-resource-resource-ltik-ltik",
    path: _91ltik_93Y3DPYiUg3zMeta?.path ?? "/lti-product-selection/:spaceId()/class/:classId()/resource/:resource()/ltik/:ltik()",
    meta: _91ltik_93Y3DPYiUg3zMeta || {},
    alias: _91ltik_93Y3DPYiUg3zMeta?.alias || [],
    redirect: _91ltik_93Y3DPYiUg3zMeta?.redirect,
    component: () => import("/app/apps/onboarding/pages/lti-product-selection/[spaceId]/class/[classId]/resource/[resource]/ltik/[ltik].vue").then(m => m.default || m)
  },
  {
    name: _91ltik_93kDfkhSYzQ1Meta?.name ?? "lti-progress-selection-spaceId-class-classId-resource-resource-ltik-ltik",
    path: _91ltik_93kDfkhSYzQ1Meta?.path ?? "/lti-progress-selection/:spaceId()/class/:classId()/resource/:resource()/ltik/:ltik()",
    meta: _91ltik_93kDfkhSYzQ1Meta || {},
    alias: _91ltik_93kDfkhSYzQ1Meta?.alias || [],
    redirect: _91ltik_93kDfkhSYzQ1Meta?.redirect,
    component: () => import("/app/apps/onboarding/pages/lti-progress-selection/[spaceId]/class/[classId]/resource/[resource]/ltik/[ltik].vue").then(m => m.default || m)
  },
  {
    name: _91ltik_938C6FNZZzkkMeta?.name ?? "lti-teacher-dashboard-spaceId-class-classId-ltik-ltik",
    path: _91ltik_938C6FNZZzkkMeta?.path ?? "/lti-teacher-dashboard/:spaceId()/class/:classId()/ltik/:ltik()",
    meta: _91ltik_938C6FNZZzkkMeta || {},
    alias: _91ltik_938C6FNZZzkkMeta?.alias || [],
    redirect: _91ltik_938C6FNZZzkkMeta?.redirect,
    component: () => import("/app/apps/onboarding/pages/lti-teacher-dashboard/[spaceId]/class/[classId]/ltik/[ltik].vue").then(m => m.default || m)
  },
  {
    name: oktaWyeQaTzaaZMeta?.name ?? "okta",
    path: oktaWyeQaTzaaZMeta?.path ?? "/okta",
    meta: oktaWyeQaTzaaZMeta || {},
    alias: oktaWyeQaTzaaZMeta?.alias || [],
    redirect: oktaWyeQaTzaaZMeta?.redirect,
    component: () => import("/app/apps/onboarding/pages/okta.vue").then(m => m.default || m)
  },
  {
    name: parental_45consentO3wTq1mVVfMeta?.name ?? "parental-consent",
    path: parental_45consentO3wTq1mVVfMeta?.path ?? "/parental-consent",
    meta: parental_45consentO3wTq1mVVfMeta || {},
    alias: parental_45consentO3wTq1mVVfMeta?.alias || [],
    redirect: parental_45consentO3wTq1mVVfMeta?.redirect,
    component: () => import("/app/apps/onboarding/pages/parental-consent.vue").then(m => m.default || m)
  },
  {
    name: privacyFEA7SJJYubMeta?.name ?? "privacy",
    path: privacyFEA7SJJYubMeta?.path ?? "/privacy",
    meta: privacyFEA7SJJYubMeta || {},
    alias: privacyFEA7SJJYubMeta?.alias || [],
    redirect: privacyFEA7SJJYubMeta?.redirect,
    component: () => import("/app/apps/onboarding/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: register_45_91user_93ZbxKT0U3tAMeta?.name ?? "register-user",
    path: register_45_91user_93ZbxKT0U3tAMeta?.path ?? "/register-:user()",
    meta: register_45_91user_93ZbxKT0U3tAMeta || {},
    alias: register_45_91user_93ZbxKT0U3tAMeta?.alias || [],
    redirect: register_45_91user_93ZbxKT0U3tAMeta?.redirect,
    component: () => import("/app/apps/onboarding/pages/register-[user].vue").then(m => m.default || m)
  },
  {
    name: regoptions8USvTGKJj9Meta?.name ?? "regoptions",
    path: regoptions8USvTGKJj9Meta?.path ?? "/regoptions",
    meta: regoptions8USvTGKJj9Meta || {},
    alias: regoptions8USvTGKJj9Meta?.alias || [],
    redirect: regoptions8USvTGKJj9Meta?.redirect,
    component: () => import("/app/apps/onboarding/pages/regoptions.vue").then(m => m.default || m)
  },
  {
    name: social_45loginrzjZZFAV66Meta?.name ?? "social-login",
    path: social_45loginrzjZZFAV66Meta?.path ?? "/social-login",
    meta: social_45loginrzjZZFAV66Meta || {},
    alias: social_45loginrzjZZFAV66Meta?.alias || [],
    redirect: social_45loginrzjZZFAV66Meta?.redirect,
    component: () => import("/app/apps/onboarding/pages/social-login.vue").then(m => m.default || m)
  },
  {
    name: terms56rJL2k8eSMeta?.name ?? "terms",
    path: terms56rJL2k8eSMeta?.path ?? "/terms",
    meta: terms56rJL2k8eSMeta || {},
    alias: terms56rJL2k8eSMeta?.alias || [],
    redirect: terms56rJL2k8eSMeta?.redirect,
    component: () => import("/app/apps/onboarding/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: verification_45link_45expiredZuz0h011W4Meta?.name ?? "verification-link-expired",
    path: verification_45link_45expiredZuz0h011W4Meta?.path ?? "/verification-link-expired",
    meta: verification_45link_45expiredZuz0h011W4Meta || {},
    alias: verification_45link_45expiredZuz0h011W4Meta?.alias || [],
    redirect: verification_45link_45expiredZuz0h011W4Meta?.redirect,
    component: () => import("/app/apps/onboarding/pages/verification-link-expired.vue").then(m => m.default || m)
  },
  {
    name: homeRnoOz6vqi0Meta?.name ?? "index",
    path: homeRnoOz6vqi0Meta?.path ?? "/",
    meta: homeRnoOz6vqi0Meta || {},
    alias: homeRnoOz6vqi0Meta?.alias || [],
    redirect: homeRnoOz6vqi0Meta?.redirect,
    component: () => import("~/pages/home.vue").then(m => m.default || m)
  }
]