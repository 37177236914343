import cavClient from 'libs-cavalier-integration/frontend/index.js';

if (process.env.NODE_ENV === 'production') {
  cavClient.init('onboarding');
  cavClient.capturePageViewEvents();
}

export default defineNuxtPlugin({
  parallel: true
});
