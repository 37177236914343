import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const publicConfig = useRuntimeConfig().public;

  if (
    !publicConfig.sentryEnabled ||
    !publicConfig.sentryDSN ||
    publicConfig.appEnv === 'local'
  ) {
    return;
  }

  Sentry.setTag('app', publicConfig.assetsAppFolder);
  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: publicConfig.sentryDSN,
    environment: publicConfig.appEnv,
    release:
      publicConfig.appEnv +
      '-' +
      publicConfig.assetsAppFolder +
      '-' +
      publicConfig.versioningTimestamp,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      })
    ],
    trackComponents: true,
    tracesSampleRate: 0.1
  });
});
