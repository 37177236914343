import revive_payload_client_btraWSxyk3 from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_axios@1.6.8_encodi_h2bxmv5v3jvohsxzhhkn7hwnqm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_uqIzOtSmLE from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_axios@1.6.8_encodi_h2bxmv5v3jvohsxzhhkn7hwnqm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_k9vWXGx3Ed from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_axios@1.6.8_encodi_h2bxmv5v3jvohsxzhhkn7hwnqm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_ZutMqe2MAu from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_axios@1.6.8_encodi_h2bxmv5v3jvohsxzhhkn7hwnqm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/app/apps/onboarding/.nuxt/components.plugin.mjs";
import prefetch_client_xnklUR9u2k from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_axios@1.6.8_encodi_h2bxmv5v3jvohsxzhhkn7hwnqm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_C81ZgipaZ9 from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.2.0_rollup@4.16.4_vue@3.4.25_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_h2FFukplxm from "/app/node_modules/.pnpm/nuxt-viewport@2.1.4_rollup@4.16.4_vue@3.4.25_typescript@5.4.5_/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import plugin_1ZesF1dBd4 from "/app/node_modules/.pnpm/@nuxtjs+device@3.1.1_rollup@4.16.4/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_ntk1TrInIM from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_axios@1.6.8_encodi_h2bxmv5v3jvohsxzhhkn7hwnqm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _0_sentry_client_jQYk0bBF56 from "/app/layers/base/plugins/0.sentry.client.ts";
import _1_gigya_plugin_client_U491P69K7H from "/app/layers/base/plugins/1.gigya-plugin.client.ts";
import _2_user_session_validation_client_BYPNrXf3eW from "/app/layers/base/plugins/2.user-session-validation.client.ts";
import bootstrap_client_n243PjUQtC from "/app/layers/base/plugins/bootstrap.client.ts";
import focus_visible_client_JIkamKZ8y6 from "/app/layers/base/plugins/focus-visible.client.ts";
import gtag_plugin_client_V012QvoJvK from "/app/layers/base/plugins/gtag-plugin.client.ts";
import http_interceptor_9I5iYs0has from "/app/layers/base/plugins/http-interceptor.ts";
import _0_gigya_plugin_client_VZq2p4KDAf from "/app/apps/onboarding/plugins/0.gigya-plugin.client.ts";
import cavalier_client_LYWekonOaz from "/app/apps/onboarding/plugins/cavalier.client.ts";
import image_thumbnail_update_G2rT6oNNpa from "/app/apps/onboarding/plugins/image-thumbnail-update.ts";
export default [
  revive_payload_client_btraWSxyk3,
  unhead_uqIzOtSmLE,
  router_k9vWXGx3Ed,
  payload_client_ZutMqe2MAu,
  components_plugin_KR1HBZs4kY,
  prefetch_client_xnklUR9u2k,
  i18n_C81ZgipaZ9,
  plugin_client_h2FFukplxm,
  plugin_1ZesF1dBd4,
  chunk_reload_client_ntk1TrInIM,
  _0_sentry_client_jQYk0bBF56,
  _1_gigya_plugin_client_U491P69K7H,
  _2_user_session_validation_client_BYPNrXf3eW,
  bootstrap_client_n243PjUQtC,
  focus_visible_client_JIkamKZ8y6,
  gtag_plugin_client_V012QvoJvK,
  http_interceptor_9I5iYs0has,
  _0_gigya_plugin_client_VZq2p4KDAf,
  cavalier_client_LYWekonOaz,
  image_thumbnail_update_G2rT6oNNpa
]