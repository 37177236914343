import validate from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_axios@1.6.8_encodi_h2bxmv5v3jvohsxzhhkn7hwnqm/node_modules/nuxt/dist/pages/runtime/validate.js";
export const globalMiddleware = [
  validate
]
export const namedMiddleware = {
  "check-authenticated": () => import("/app/layers/base/middleware/check-authenticated.ts"),
  "common-loader": () => import("/app/apps/onboarding/middleware/common-loader.ts"),
  "lti-onboarding": () => import("/app/apps/onboarding/middleware/lti-onboarding.ts"),
  "page-redirection": () => import("/app/apps/onboarding/middleware/page-redirection.ts"),
  "parent-login": () => import("/app/apps/onboarding/middleware/parent-login.ts"),
  "redirect-home": () => import("/app/apps/onboarding/middleware/redirect-home.ts")
}